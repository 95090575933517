<template>
  <!--
    The view for the QuestionTemplateList-component.
    Used to display the the templates that hold the questions for installations
  -->
  <Portlet
    :title="$t('menu.templates')"
    class="installtionQuestions"
    icon="question"
  >
    <template slot="buttons">
      <div class="btn-group btn-group-sm">
        <button
          class="btn btn-sm btn-primary"
          @click="showAddTemplate"
        >
          <font-awesome-icon
            class="mr-2"
            icon="plus"
          />
          <span>{{ $t('add') }}</span>
        </button>
      </div>
    </template>
    <InstallationQuestionTemplateList
      ref="templateList" 
      @reloadAuditLogs="reloadAuditLogs"
    />
    <DatabaseAuditLogList
      ref="databaseAuditLogList"
      :is-question-templates="true"
      @reload="reloadTemplateList"
    /> 
  </Portlet>
</template>

<script>
export default {
  name: 'InstallationQuestionTemplates',
  components: {
    InstallationQuestionTemplateList: () => import('@/components/Installation/InstallationQuestionTemplateList.vue'),
    DatabaseAuditLogList: () => import('@/components/DatabaseAuditLogs/DatabaseAuditLogsList.vue')
  },
  metaInfo () {
    return {
      title: this.$t('menu.installationQuestions')
    }
  },
  methods: {
    reloadAuditLogs () {
      this.$refs.databaseAuditLogList.reloadAuditLogs();
    },
    reloadTemplateList () { 
      this.$refs.templateList.getAllTemplates();
    },
    showAddTemplate () {
      this.$refs.templateList.showAddTemplate();
    },
  }
}
</script>